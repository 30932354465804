

const Request = (url, data) => {
    console.log('Request called');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", GetHeader());

    const requestOptions = {
        method: !!data ? "POST" : "GET",
        headers: myHeaders,
        redirect: "follow"
    };
    if (requestOptions.method === "POST") {
        requestOptions.body = JSON.stringify(data)
        console.log("RequestOptions: ", requestOptions);
    }

    return fetch(`https://api.clickup.com/api/v2/${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}
const LogTime = (taskId, description, hours) => {
    const url = `team/${GetTeamId()}/time_entries`;
    const data = {
        description: description, 
        created_with: "customSuperLogger",
        start: Date.now(),
        //end: Date.now(),
        tid: taskId, 
        duration: hours * 60 * 60 * 1000

    };
    
    

    return Request(url, data);

    
}

const GetMyIdRequest = () => {
    return Request('user');
}

const RemoveLogRequest = (logId) => {
    console.log('Request called');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", GetHeader());

    const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow"
    };

    return fetch(`https://api.clickup.com/api/v2/team/${GetTeamId()}/time_entries/${logId}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}

const GetTeamId = () => localStorage.getItem('teamId');

const GetHeader = () => {
    return localStorage.getItem('token');
}

export {Request, LogTime, RemoveLogRequest, GetMyIdRequest}; 
