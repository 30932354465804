import './App.css';
import React from 'react';
import {Request, LogTime, RemoveLogRequest, GetMyIdRequest} from './requests';
import DataTable,{  defaultThemes } from 'react-data-table-component';
// import Modal from 'react-modal';
import "bootstrap/dist/css/bootstrap.min.css";
import {Modal, Button, Form, Card, CardBody} from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Spinner from 'react-bootstrap/Spinner';

const customStyles = {
	header: {
		style: {
			minHeight: '56px',
		},
	},
	headRow: {
		style: {
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
      paddingLeft: '0',
      paddingRight: '0',
			borderTopColor: defaultThemes.default.divider.default,
		},
	},
	headCells: {
		style: {
			'&:not(:last-of-type)': {
				borderRightStyle: 'solid',
				borderRightWidth: '1px',
       
				borderRightColor: defaultThemes.default.divider.default,
			},
      paddingLeft: '8px',
      paddingRight: '8px',
		},
	},
	cells: {
		style: {
			'&:not(:last-of-type)': {
				borderRightStyle: 'solid',
				borderRightWidth: '1px',
				borderRightColor: defaultThemes.default.divider.default,
			},
		},
    paddingLeft: '8px',
    paddingRight: '8px',
	},
};

const columns = [
	{
		name: 'Task',
    grow: 3,
    wrap: true,
    center: true, 
    maxWidth: "60%",
    minWidth: '10px',
    paddingLeft: '8px',
    paddingRight: '8px',
		selector: row => row.caption,
	},
	// {
	// 	name: 'Logged',
  //   maxWidth: "15%",
  //   minWidth: '10px',
	// 	selector: row => row.loggedTotal,
	// },
  {
		name: 'H',
    maxWidth: "15%",
    minWidth: '10px',
    paddingLeft: '8px',
    paddingRight: '8px',
    center: true, 
		selector: row =>row.loggedToday,
	},
  {
		name: '',

    maxWidth: "10%",
		selector: row => {
        return <button onClick={row.createNew} className="btn btn-primary">Log</button>
    },
    paddingLeft: '8px',
    paddingRight: '8px',
	},
];

const columnsInner = [
	
	{
		name: 'Note',
    grow: 3,
    wrap: true,

    maxWidth: "50%",
    minWidth: '10px',
		selector: row => row.description,
	},
  {
		name: 'Hours',
    maxWidth: "15%",
    minWidth: '10px',
		selector: row => `${row.taskHours}h`,
	},
  {
		name: '',
    maxWidth: "40%",
    minWidth: '10px',
		selector: row => {
      return <button onClick={row.deleteLog} className="btn btn-danger">Delete</button>
  },
	},
];

const ExpandedComponent = ({ data }) => <Card style={{margin:'20px'}}>
  <CardBody>
  {!!data.todayTasks && data.todayTasks.length > 0 && <div style={{width: '100%'}}>
      <DataTable  style={{maxWidth: '100%', width: '100%', display: 'initial'}}
        dense
        noHeader
        noTableHead
        title="Today logs"
        customStyles={customStyles}
        columns={columnsInner}
        data={data.todayTasks}/>
    </div>
  }
  {(!!data.todayTasks) && (data.todayTasks.length === 0) && <div>
      No logs today
    </div>
  }
  </CardBody>

</Card>;


class App extends React.Component {
  componentDidMount() {
    console.log('componentDidMount: ');
    
    this.pageLoad(); 
  }

  async pageLoad(force = false){
    if (localStorage.getItem('token') === null) {
      this.setState({initialize: true});
    } else {
      this.setState({initialize: false});
      await this.getMyId(); 
      await this.retrieveTeams();
      await this.getRecentTasks(force); 
    }
    
  }

  async getMyId(){
    await GetMyIdRequest().then(result => {
      console.log('currentUser: ', result);
      localStorage.setItem('userId', result.user.id);
      localStorage.setItem('userName', result.user.username);
    })
  }

  constructor(){
    super();
    this.state = {
      teams: [], 
      teamId: undefined, 
      tasks: [], 
      assignedTasks: [],
      tasksLoading: false,
      tableTasks: [], 
      modalIsOpen: false, 
      hoursSpecified: 0, 
      descriptionSpecified: 0, 
      taskIdSpecified: "", 
      initialize: false, 

      loading: true, 
      logIdSpecified: "", 
      deleteConfirmationOpen: false, 
      totalLoggedToday: 0, 
      totalLoggedYesterday : 0, 
      tokenSpecified: ''
    }
    this.closeModal = this.closeModal.bind(this);
    this.hoursInput = this.hoursInput.bind(this);
    this.descriptionInput = this.descriptionInput.bind(this);
    this.saveRecord = this.saveRecord.bind(this);
    this.deleteLogAction = this.deleteLogAction.bind(this);
    this.tokenChanged = this.tokenChanged.bind(this);
    this.storeToken = this.storeToken.bind(this);
    this.refresh = this.refresh.bind(this);
    this.logOut = this.logOut.bind(this);
  }


  async retrieveTeams(){
    if (localStorage.getItem('teamId') === null) {
        await Request('team').then(result => {
          localStorage.setItem('teamId', result.teams[0].id);
          this.setState({teamId: result.teams[0].id}, () => {})

        })
    } else {
      this.setState({teamId: localStorage.getItem('teamId')}, () => {})
    }
  }

  async getRecentTasks(force){
    if (force || (this.state.tasks.length === 0 && !this.state.tasksLoading)) {
      return await this.setState({tasksLoading: true}, async () => {
        await Promise.all(
          [
            Request(`team/${this.state.teamId}/time_entries`).then(async result =>  {
              console.log('got time entries: ', result);
              await this.setState({tasks: result.data, tasksLoading: false});
              return Promise.resolve()
            }),
            Request(`team/${this.state.teamId}/task?assignees[]=${localStorage.getItem('userId')}`).then(async result => {
              console.log('got assigned tasks: ', result);
              await this.setState({assignedTasks: result.tasks, assignedTasksLoading: false});
              return Promise.resolve()
            }).then(_ => {
              this.setState({loading: false}, this.parseTasks);
            })
          ]
        )
        
      })
    }
    
    return Promise.resolve();
  }

  async parseTasks(){
    let totalLoggedToday = 0; 
    let totalLoggedYesterday = 0; 
    
    const {tasks, assignedTasks} = this.state; 
    const taskMap = {};
    tasks.forEach(task => {
      const taskDate = this.getDatetime(task.end);
      const taskHours = this.getHours(task.duration); 
      const loggedToday = this.isToday(taskDate) ? taskHours : 0; 
      totalLoggedToday += loggedToday; 
      totalLoggedYesterday += (this.isYesterday(taskDate) ? taskHours : 0);
      task.taskHours = taskHours; 

      if (!taskMap[task.task.id]) {
        taskMap[task.task.id] = {
          caption: task.task.name, 
          loggedTotal: taskHours, 
          loggedToday: loggedToday,
          date_created: task.task.date_created,
          todayTasks: [],
          createNew: () => this.createNewTimelog(task.task.id),
          id: task.task.id
        };
      } else {
        taskMap[task.task.id].loggedTotal += taskHours;
        taskMap[task.task.id].loggedToday += loggedToday; 
      }
      
      task.deleteLog = () => this.deleteLog(task.id)
      if(loggedToday > 0) taskMap[task.task.id].todayTasks.push(task);
    });
    assignedTasks.forEach(task => {
      if (!taskMap[task.id]) {
        taskMap[task.id] = {
          caption: task.name, 
          loggedTotal: 0, 
          loggedToday: 0,
          todayTasks: [],
          date_created: task.date_created, 
          createNew: () => this.createNewTimelog(task.id),
          id: task.id
        };
      }
    })

    console.log('taskMap: ', taskMap);
    let tableTasks = []; 
    Object.keys(taskMap).forEach(taskId => {
      tableTasks.push(taskMap[taskId]);
    })

    tableTasks.sort((t1, t2 ) => {
      if (t1.todayTasks.length > t2.todayTasks.length) return -1; 
      if (t1.todayTasks.length < t2.todayTasks.length) return 1; 
      return t2.date_created - t1.date_created;
    } )

    this.setState({tableTasks: tableTasks, totalLoggedToday, totalLoggedYesterday});
  }

  getDatetime = (ms) => new Date(parseInt(ms));

 

  isToday(dt) {
    const today = new Date(); 
    return today.setHours(0,0,0,0) === dt.setHours(0,0,0,0);
  }
  isYesterday(dt) {
    const today = new Date(); 
    today.setDate(today.getDate() - 1);
    return today.setHours(0,0,0,0) === dt.setHours(0,0,0,0).add;
  }

  getHours(duration) {
    return duration / 1000 / 60 / 60; 
  }

  deleteLog(logId) {
    console.log('deleteLog: ', logId)
    this.setState({logIdSpecified: logId, deleteConfirmationOpen: true})
  }

  async deleteLogAction(){
    this.setState({loading: true, deleteConfirmationOpen: false})
    await RemoveLogRequest(this.state.logIdSpecified);
    this.setState({logIdSpecified: ""})
    this.getRecentTasks(true);
  }

  createNewTimelog(taskId) {
    this.setState({taskIdSpecified: taskId, modalIsOpen: true});
  }

  afterOpenModal() {
    console.log('afterOpenModal called');
  }

  closeModal() {
    console.log('closeModal called');
    this.setState({modalIsOpen: false, deleteConfirmationOpen: false});
  }

  async saveRecord() {
    console.log('save called: ');
    this.setState({modalIsOpen: false, loading: true})
    return LogTime(
      this.state.taskIdSpecified, this.state.descriptionSpecified, this.state.hoursSpecified
    ).then(r => {
      this.setState({hoursSpecified: 0, descriptionSpecified: '', taskIdSpecified: ''})
      this.getRecentTasks(true);
    });
  }

  hoursInput(evt){
    const hours = evt;//evt.target.value; 
    console.log('hours: ', hours);
    this.setState({hoursSpecified: hours});
  }

  descriptionInput(evt) {
    const desc = evt.target.value; 
    console.log('desc: ', desc);
    this.setState({descriptionSpecified: desc});
  }

  tokenChanged(evt) {
    const tokenSpecified = evt.target.value; 
    this.setState({tokenSpecified: tokenSpecified});
  }

  storeToken(){
    localStorage.setItem('token', this.state.tokenSpecified);
    this.pageLoad(); 
  }

  refresh(){
    this.setState({loading: true});
    this.pageLoad(true);
  }

  logOut(){
    localStorage.setItem('token', null);
    this.pageLoad();
  }
  

  render() {
    return (
      <div className="App">
        {this.state.initialize && <div>
          <Form>
              <Form.Group className="mb-3" controlId="exampleForm.tokenS">
                <Form.Label>ClickUp Personal API Key</Form.Label>
                <Form.Control as="textarea" rows={2} onChange={this.tokenChanged}/>
              </Form.Group>
              <Button variant="primary" onClick={this.storeToken}>
                          Proceed
                      </Button>
            </Form>

            <a href="https://help.clickup.com/hc/en-us/articles/6303426241687-Use-the-ClickUp-API#personal-api-key" target='_blank' rel="noreferrer">
              Where to find Personal API Key
            </a>
          </div>}
        {this.state.initialize === false && <div>
          <Card>
            <Card.Body>
              Hi {localStorage.getItem('userName')}!<br/>
              Logged today: {this.state.totalLoggedToday} h<br/>
              <Button variant='light' onClick={this.refresh}>Refresh</Button>
              
              
              </Card.Body>
              
            {/* <Card.Body>Logged yesterday: {this.state.totalLoggedYesterday} h</Card.Body> */}
          </Card>

          {this.state.loading && <FullScreenSpinner/>}
          <h2>Tasks</h2>
          <DataTable
            columns={columns}
            data={this.state.tableTasks}
            expandableRows 
            
            expandableRowExpanded={row => row.loggedToday >0}
            expandableRowsComponent={ExpandedComponent}
            ></DataTable>
        

        <Modal
          show={this.state.modalIsOpen}
          onHide={this.closeModal} backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>New Time Entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} onChange={this.descriptionInput}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlHours1">
                <Form.Label>Hours</Form.Label>
                <Form.Control type="number" onChange={(evt) => this.hoursInput(evt.target.value)} value={this.state.hoursSpecified}/>
              </Form.Group>

            </Form>
            <ButtonGroup aria-label="Basic example" style={{width: '100%', height: '100px'}}>
              <Button variant="secondary" onClick={() => this.hoursInput(1)}>1</Button>
              <Button variant="secondary" onClick={() => this.hoursInput(2)}>2</Button>
              <Button variant="secondary" onClick={() => this.hoursInput(3)}>3</Button>
              <Button variant="secondary" onClick={() => this.hoursInput(4)}>4</Button>
              <Button variant="secondary" onClick={() => this.hoursInput(5)}>5</Button>
              <Button variant="secondary" onClick={() => this.hoursInput(6)}>6</Button>
              <Button variant="secondary" onClick={() => this.hoursInput(7)}>7</Button>
              <Button variant="secondary" onClick={() => this.hoursInput(8)}>8</Button>
            </ButtonGroup>
          </Modal.Body>
          <Modal.Footer>
                      <Button variant="primary" onClick={this.saveRecord}>
                          Save
                      </Button>
                      <Button variant="light" onClick={this.closeModal}>
                          Cancel
                      </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteConfirmationOpen}
          onHide={this.closeModal} backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete log?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Confirm deletion
          </Modal.Body>
          <Modal.Footer>
                      <Button variant="danger" onClick={this.deleteLogAction}>
                          Delete
                      </Button>
                      <Button variant="light" onClick={this.closeModal}>
                          Cancel
                      </Button>
          </Modal.Footer>
        </Modal>
          </div>}
        
      </div>
    );
  }
}

const FullScreenSpinner = () => {
  return <div style={{position: 'absolute',width: '100%',
    height: '100%',
    zIndex: '1000',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fafafaa9',
    alignItems: 'center'}}>
      <Spinner animation='grow'/>
  </div>
}

export default App;
